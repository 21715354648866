import * as React from "react"

import HelmetSlot from "../components/Helmet"
import Navigation from "../components/Navigation"
import cx from "classnames"
import Footer from "../components/Footer"
import Gallery from "../components/Gallery"
import Contact from "../components/Contact"
import "../theme/main.less"

import {
  rundbeckenGallery,
  polyfaserGallery,
  aussenbeckenGallery,
  sonstigesGallery,
} from "../data/schwimmbadGallery"

const SchwimmbadPage = () => {
  const [tab, setTab] = React.useState(0)
  console.log(tab)
  return (
    <>
      <HelmetSlot
        title="Schwimmbadbau"
        description="Ihr erfahrener Poolbauer in Siegburg. Wir kommen ihren Wünschen nach und finden gemeinsam den perfekten Pool für ihr Zuhause."
      />
      <main>
        <body className="schwimmbadpage">
          <div className="view">
            <div className="landing">
              <Navigation />
            </div>
            <div className="main">
              <h1>Sauna & Schwimmbadbau</h1>
              <div className="content">
                <p className="text">
                  Unser Betrieb bietet langjährige Erfahrung im Bereich
                  Schwimmbad- und Saunabau. Wir sprechen mit ihnen über ihre
                  Wünsche und Vorstellungen, versuchen diese bestmöglich
                  umzusetzen und das alles zu fairen Konditionen. Bei der
                  Auswahl der Materialien zählen wir auf qualtiativ hochwertig
                  ausgestattete Lieferanten und eine breite Auswahl an
                  verschiedensten Bodenbelägen, Größen, Verkleidungen, Pumpen
                  und werden ihren Sonderwünschen gerecht. Denn in der heißen
                  Jahreszeit liegt und entspannt es sich am besten im eigenen
                  Pool und nicht am überfüllten Badesee. Sollte ihnen der Sommer
                  dennoch nicht heiß genug sein: Wir bauen ihnen auch eine Sauna
                  ein. Auch hier bieten wir eine breite Produktpalette und
                  kommen ihren Wünschen nach. Ob Indoor- oder Outdoorsauna, ob
                  groß oder klein, unsere Erfahrung macht den Traum der eigenen
                  Sauna möglich.
                  <br />
                  <br />
                  Besuchen Sie gerne unsere Bildergalerie etwas weiter unten und
                  überzeugen Sie sich von unserer Arbeit. Gemeinsam erschaffen
                  wir ihre Wellnessoase.
                </p>
                <Contact />
              </div>
              <h4>Bilder und Impressionen</h4>
              <div className="tabs">
                <div
                  className={cx("tab2", { active: tab === 0 })}
                  onClick={() => setTab(0)}
                >
                  <span>Einstückbecken</span>
                </div>

                <div
                  className={cx("tab3", { active: tab === 1 })}
                  onClick={() => setTab(1)}
                >
                  <span>Außenbecken</span>
                </div>
                <div
                  className={cx("tab1", { active: tab === 2 })}
                  onClick={() => setTab(2)}
                >
                  <span>Rundbecken</span>
                </div>
                <div
                  className={cx("tab4", { active: tab === 3 })}
                  onClick={() => setTab(3)}
                >
                  <span> Folienauskleidungen</span>
                </div>
              </div>
              <Gallery
                items={
                  tab === 2
                    ? rundbeckenGallery
                    : tab === 0
                    ? polyfaserGallery
                    : tab === 1
                    ? aussenbeckenGallery
                    : tab === 3
                    ? sonstigesGallery
                    : sonstigesGallery
                }
              />
              <div></div>
            </div>
            <Footer />
          </div>
        </body>
      </main>
    </>
  )
}

export default SchwimmbadPage
