export const rundbeckenGallery = [
  {
    original: "https://i.imgur.com/QQf4RNj.jpg",
    thumbnail: "https://i.imgur.com/QQf4RNj.jpg",
    description: "Rundbecken 5,50m Durchmesser",
  },
  {
    original: "https://i.imgur.com/CdVRa5S.jpg",
    thumbnail: "https://i.imgur.com/CdVRa5S.jpg",
  },
  {
    original: "https://i.imgur.com/qBlUebu.jpg",
    thumbnail: "https://i.imgur.com/qBlUebu.jpg",
    description: "Rundbecken aus der Vogelperspektive",
  },
  {
    original: "https://i.imgur.com/wfoJ5rR.jpg",
    thumbnail: "https://i.imgur.com/wfoJ5rR.jpg",
    description: "",
  },
]

export const polyfaserGallery = [
  {
    original: "https://i.imgur.com/corjOh1.jpg",
    thumbnail: "https://i.imgur.com/corjOh1.jpg",
    description: "",
  },
  {
    original: "https://i.imgur.com/5R2TBT7.jpg",
    thumbnail: "https://i.imgur.com/5R2TBT7.jpg",
    description: "",
  },
  {
    original: "https://i.imgur.com/AUWM3Kh.jpg",
    thumbnail: "https://i.imgur.com/AUWM3Kh.jpg",
    description: "",
  },
  {
    original: "https://i.imgur.com/ObOquIA.jpg",
    thumbnail: "https://i.imgur.com/ObOquIA.jpg",
    description: "",
  },
  {
    original: "https://i.imgur.com/qCCLdfL.jpg",
    thumbnail: "https://i.imgur.com/qCCLdfL.jpg",
    description: "",
  },
  {
    original: "https://i.imgur.com/1DMUkod.jpg",
    thumbnail: "https://i.imgur.com/1DMUkod.jpg",
    description: "",
  },
  {
    original: "https://i.imgur.com/x2mcbVX.jpg",
    thumbnail: "https://i.imgur.com/x2mcbVX.jpg",
    description: "Polyfaser Einstückbecken",
  },
  {
    original: "https://i.imgur.com/lMIFraZ.jpg",
    thumbnail: "https://i.imgur.com/lMIFraZ.jpg",
    description: "",
  },
  {
    original: "https://i.imgur.com/39fcZKl.jpg",
    thumbnail: "https://i.imgur.com/39fcZKl.jpg",
    description: "",
  },
]

export const aussenbeckenGallery = [
  {
    original: "https://i.imgur.com/MTpu5UU.jpg",
    thumbnail: "https://i.imgur.com/MTpu5UU.jpg",
    description:
      "Beleuchtetes massives Außenschwimmbad auf einer Terasse bei Nacht",
  },
  {
    original: "https://i.imgur.com/aXMjC8W.jpg",
    thumbnail: "https://i.imgur.com/aXMjC8W.jpg",
    description:
      "Beleuchtetes massives Außenschwimmbad auf einer Terasse bei Nacht",
  },
]

export const sonstigesGallery = [
  {
    original: "https://i.imgur.com/CJGIC0a.jpg",
    thumbnail: "https://i.imgur.com/CJGIC0a.jpg",
    description: "Schwimmbadbecken aus Beton, während des Baus",
  },
  {
    original: "https://i.imgur.com/6pBBJPy.jpg",
    thumbnail: "https://i.imgur.com/6pBBJPy.jpg",
    description: "",
  },
  {
    original: "https://i.imgur.com/TpwqIFZ.jpg",
    thumbnail: "https://i.imgur.com/TpwqIFZ.jpg",
    description: "",
  },
  {
    original: "https://i.imgur.com/Al84ZYE.jpg",
    thumbnail: "https://i.imgur.com/Al84ZYE.jpg",
    description: "Beckenauskleidung des Betonrohbeckens.",
  },
  {
    original: "https://i.imgur.com/nHfkbNz.jpg",
    thumbnail: "https://i.imgur.com/nHfkbNz.jpg",
    description: "",
  },
  {
    original: "https://i.imgur.com/m8d5u8H.jpg",
    thumbnail: "https://i.imgur.com/m8d5u8H.jpg",
    description: "Fertige Beckenauskleidung nach Kundenwunsch.",
  },
  {
    original: "https://i.imgur.com/3FIuRSC.jpg",
    thumbnail: "https://i.imgur.com/3FIuRSC.jpg",
    description: "",
  },
  {
    original: "https://i.imgur.com/awImW1E.jpg",
    thumbnail: "https://i.imgur.com/awImW1E.jpg",
    description:
      "Maßgefertigte Abdeckung für ein Schwimmbad im Außenbereich. Eine perfekte Lösung für Laub und falls Kinder in der Nähe sind.",
  },
]
